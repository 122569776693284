<template>
  <div>
    <!-- Picture in Picture -->
    <button :class="['btn', pipState]" @click="onClickPip">
      <fa icon="share-square" />
    </button>
    <!-- 録画 -->
    <button :class="['btn', recState]" @click="onClickRec">
      <fa :icon="recIconText" />
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ControlButtons',
  props: {
    isRec: {
      type: Boolean,
      required: true,
    },
    isPip: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['on-click-pip', 'on-click-rec'],
  setup(props, { emit }) {
    const recState = computed(() => props.isRec ? 'rec' : 'not-rec')
    const pipState = computed(() => props.isPip ? 'pip' : 'not-pip')

    const recIconText = computed(() => props.isRec ? 'square' : 'circle')

    const onClickPip = () => {
      emit('on-click-pip')
    }

    const onClickRec = () => {
      emit('on-click-rec')
    }

    return {
      recState,
      pipState,
      onClickPip,
      onClickRec,
      recIconText,
    }
  },
})
</script>

<style lang="scss" scoped>
.btn {
  margin: 0 15px;
  border: none;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  > .svg-inline--fa {
    font-size: 1.3em;
  }
}
// 録画ボタンのスタイル
.not-rec {
  background: rgba(255, 255, 255, 0.6);
  > .fa-circle {
    color: red;
  }
}
.rec {
  background: #FF3459;
  > .fa-square {
    color: white;
  }
}
// Picture in Pictureのスタイル
.not-pip {
  background: rgba(255, 255, 255, 0.6);
  > .fa-share-square {
    color: rgba(0, 0, 0, 0.6);
  }
}
.pip {
  background: #0FDA24;
  > .fa-share-square {
    color: white;
  }
}
</style>
