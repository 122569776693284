export class ApplicationError implements MediaStreamError {
  name = ''
  constraintName: string | null = ''
  message: string | null = ''

  // TODO: MediaRecorderのメソッド実行時はMediaStreamErrorではないので、
  //       のちのちMediaStreamとMediaRecorder用の親クラスを分ける
  constructor(public error: MediaStreamError) {
    console.log(`name: ${error.name}, message: ${error.message}`)
    this.name = error.name
    this.constraintName = error.constraintName
    this.message = error.message
  }
}
