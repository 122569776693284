
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ControlButtons',
  props: {
    isRec: {
      type: Boolean,
      required: true,
    },
    isPip: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['on-click-pip', 'on-click-rec'],
  setup(props, { emit }) {
    const recState = computed(() => props.isRec ? 'rec' : 'not-rec')
    const pipState = computed(() => props.isPip ? 'pip' : 'not-pip')

    const recIconText = computed(() => props.isRec ? 'square' : 'circle')

    const onClickPip = () => {
      emit('on-click-pip')
    }

    const onClickRec = () => {
      emit('on-click-rec')
    }

    return {
      recState,
      pipState,
      onClickPip,
      onClickRec,
      recIconText,
    }
  },
})
