
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useCameraStream } from '@/features/home/composables/useCameraStream'
import { usePictureInPicture, HTMLVideoElementWithPip } from '@/features/home/composables/usePictureInPicture'
import ControlButtons from '@/features/home/components/ControlButtons.vue'
import { useDisplayStream } from '@/features/home/composables/useDisplayStream'
import { useRecordStream } from '@/features/home/composables/useRecordStream'
import { useTimeManagement } from '@/features/home/composables/useTimeManagement'

export default defineComponent({
  name: 'Home',
  components: {
    ControlButtons
  },
  setup() {
    const { cameraStream, handleLiveCameraStream } = useCameraStream()
    const { displayStream, isLiveDisplayStream, handleLiveDisplayStream } = useDisplayStream()
    const { handleRecordStream } = useRecordStream(cameraStream, displayStream)
    const { enablePip, disablePip: endPip, togglePipState, isEnabledPip } = usePictureInPicture()
    const { startTime, endTime, } = useTimeManagement(handleLiveDisplayStream, handleRecordStream)

    const onHandleLiveDisplayStream = async () => {
      if (!isLiveDisplayStream.value) {
        await handleLiveDisplayStream('start')
        await handleRecordStream('start')
        startTime()
      } else {
        endTime()
        await handleLiveDisplayStream('stop')
        await handleRecordStream('stop')
      }
    }

    const videoElement = ref<HTMLVideoElementWithPip|null>(null)
    onMounted(async() => {
      await handleLiveCameraStream('start')
      videoElement.value = document.querySelector('video') as HTMLVideoElementWithPip
      // Picture-in-Pictureのイベントを登録
      videoElement.value.addEventListener('enterpictureinpicture', () => togglePipState(true))
      videoElement.value.addEventListener('leavepictureinpicture', () => togglePipState(false))
    })

    const startPip = () => {
      const videoElementValue = videoElement.value
      if (videoElementValue) {
        enablePip(videoElementValue)
      } else {
        console.error('video要素が取得できませんでした')
      }
    }

    // TODO: 処理をvideoElement、startPipと共にuse関数に移行する
    const onClickPip = () => isEnabledPip.value ? endPip() : startPip()

    const cameraStreamClass = computed(() => ({ 'hide-dispalay': isEnabledPip.value }))

    return {
      cameraStream,
      onHandleLiveDisplayStream,
      isLiveDisplayStream,
      startPip,
      endPip,
      onClickPip,
      isEnabledPip,
      cameraStreamClass,
    }
  },
})
