import { ref, computed } from 'vue'
import { ApplicationError } from '@/features/home/composables/useException'
import { EventName } from '@/features/home/types'

class CameraStreamError extends ApplicationError {
  errorMessage = () => {
    if (this.name === 'NotAllowedError'){
      return 'カメラとマイクを使用するための許可が与えられていません。デバイスへのアクセスを許可してください。許可後、ブラウザを再読み込みしてください'
    } else {
      return '定義されていないエラーです'
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCameraStream = () => {
  const cameraStream = ref<MediaStream | null>(null)
  const constraints = { video: true, audio: true }

  // カメラの開始
  const startCameraStream = async () => {
    await navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        updateCameraStream(stream)
        console.log('start cameraStream', cameraStream.value)
      }).catch((error: MediaStreamError) => {
        throw new CameraStreamError(error)
      })
  }

  // カメラの停止
  const stopCameraStream = () => {
    const tracks = cameraStream.value?.getTracks()
    tracks?.forEach((track) => track.stop())
    updateCameraStream(null)
    console.log('stop cameraStream', cameraStream.value)
  }

  // カメラの更新
  const updateCameraStream = (stream: MediaStream | null) => {
    cameraStream.value = stream
  }

  const cameraStreamTracks = computed(() => {
    return cameraStream.value?.getTracks()
  })

  const handleLiveCameraStream = async (eventName: EventName) => {
    try {
      if (eventName === 'start') {
        await startCameraStream()
      } else if (eventName === 'stop') {
        stopCameraStream()
      }
    } catch (error) {
      alert(error.errorMessage())
      return
    }
  }

  return {
    cameraStream,
    cameraStreamTracks,
    handleLiveCameraStream
  }
}
