import { ref, Ref } from 'vue'
import { ApplicationError } from '@/features/home/composables/useException'
import { EventName } from '@/features/home/types'

class RecordStreamError extends ApplicationError {
  errorMessage = () => {
    if (this.name === 'InvalidStateError') {
      return '今すぐ動画を録画することはできません。あとでもう一度試してみてください。'
    } else if (this.name === 'SecurityError') {
      return 'セキュリティ上の制限により、指定されたソースを録音することはできません。'
    } else if (this.name === 'NotSupportedError') {
      return 'デバイスがサポートしていないMIMEタイプです。'
    } else if (this.name === 'UnknownError') {
      return '分類できない、セキュリティに関連しないエラーが発生しました。'
    } else {
      return '動画を録画しようとしたときに問題が発生しました。'
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRecordStream = (cameraStream: Ref<MediaStream | null>, displayStream: Ref<MediaStream | null>) => {
  const recordedBlobs = ref<Blob[]>([])
  const mediaRecorder = ref<MediaRecorder | null>(null)
  const isDownloading = ref(false)

  // 録画の開始
  const startRecordStream = async () => {
    // 録画データをクリア
    recordedBlobs.value = []

    // 画面共有のビデオトラックを取得
    const videoStreamTracks = displayStream.value?.getVideoTracks()
    // カメラの音声トラックを取得
    const audioStreamTracks = cameraStream.value?.getAudioTracks()

    if (!videoStreamTracks || !audioStreamTracks) return

    // 画面共有のビデオトラックとカメラの音声トラックを統合
    const combinedStream = new MediaStream([...videoStreamTracks, ...audioStreamTracks])

    try {
      const mimeType = 'video/webm;codecs=vp9'
      // recorderを作成
      mediaRecorder.value = new MediaRecorder(combinedStream, { mimeType })
    } catch (error) {
      throw new RecordStreamError(error)
    }

    if (!mediaRecorder.value) return

    mediaRecorder.value.ondataavailable = (event: BlobEvent) => {
      if (event.data && event.data.size > 0) {
        // Blobデータを録画データにpush
        recordedBlobs.value.push(event.data)
      }
    }

    try {
      // 録画開始(10ミリ秒毎にBlobデータを送出)
      mediaRecorder.value.start(10)
    } catch (error) {
      throw new RecordStreamError(error)
    }
  }

  // ダウンロードするかどうかダイアログで確認
  const downloadConfirm = () => confirm('録画を終了しました。録画した動画をダウンロードしてもよろしいですか？（キャンセルを選択した場合は、録画したデータは破棄されます）')

  const stopRecordStream = () => {
    // 録画終了
    mediaRecorder.value?.stop()
    if (downloadConfirm()){
      createDownloadLink()
    }
  }

  // アンカーリンクを生成
  const createAnchor = (dataUrl: string) => {
    const anchor = document.createElement('a')
    anchor.style.display = 'none'
    anchor.href = dataUrl
    anchor.download = `movie${Date.now()}.webm`
    return anchor
  }

  // ダウンロードリンク作成
  const createDownloadLink = async () => {
    // ダウンロードリンク作成中は録画開始ボタンを非活性にする
    isDownloading.value = true

    // TODO: すぐにvideoBlobを作成すると、動画データの最後が格納されていないことがあるので3秒待つ。別途調査する。
    await new Promise(resolve => setTimeout(resolve, 3 * 1000))

    // Blobデータ作成
    const vidoBlob = new Blob(recordedBlobs.value, { type: 'video/webm' })

    // Blobデータを参照するためのURLを作成
    const dataUrl = window.URL.createObjectURL(vidoBlob)

    // アンカーリンクを生成
    const newAnchor = createAnchor(dataUrl)

    const anchor = document.body.appendChild(newAnchor)
    anchor.click()
    setTimeout(() => {
      document.body.removeChild(anchor)
      window.URL.revokeObjectURL(dataUrl)
    }, 100)

    // 録画開始ボタンを活性にする
    isDownloading.value = false
  }

  const handleRecordStream = async (eventName: EventName) => {
    try {
      if (eventName === 'start') {
        await startRecordStream()
      } else if (eventName === 'stop') {
        stopRecordStream()
      }
    } catch (error) {
      alert(error.errorMessage())
      return
    }
  }

  return {
    isDownloading,
    handleRecordStream
  }
}
