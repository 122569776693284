<template>
  <div class="container">
    <!-- TODO: videoのデザインは別途調整 -->
    <section class="video-section">
      <div class="video-box">
        <p v-if="isEnabledPip">画面共有中...</p>
        <video autoplay muted :srcObject.prop="cameraStream" :class="cameraStreamClass"></video>
      </div>
    </section>

    <control-buttons
      class="btn-area"
      :isPip="isEnabledPip"
      :isRec="isLiveDisplayStream"
      @on-click-pip="onClickPip"
      @on-click-rec="onHandleLiveDisplayStream" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useCameraStream } from '@/features/home/composables/useCameraStream'
import { usePictureInPicture, HTMLVideoElementWithPip } from '@/features/home/composables/usePictureInPicture'
import ControlButtons from '@/features/home/components/ControlButtons.vue'
import { useDisplayStream } from '@/features/home/composables/useDisplayStream'
import { useRecordStream } from '@/features/home/composables/useRecordStream'
import { useTimeManagement } from '@/features/home/composables/useTimeManagement'

export default defineComponent({
  name: 'Home',
  components: {
    ControlButtons
  },
  setup() {
    const { cameraStream, handleLiveCameraStream } = useCameraStream()
    const { displayStream, isLiveDisplayStream, handleLiveDisplayStream } = useDisplayStream()
    const { handleRecordStream } = useRecordStream(cameraStream, displayStream)
    const { enablePip, disablePip: endPip, togglePipState, isEnabledPip } = usePictureInPicture()
    const { startTime, endTime, } = useTimeManagement(handleLiveDisplayStream, handleRecordStream)

    const onHandleLiveDisplayStream = async () => {
      if (!isLiveDisplayStream.value) {
        await handleLiveDisplayStream('start')
        await handleRecordStream('start')
        startTime()
      } else {
        endTime()
        await handleLiveDisplayStream('stop')
        await handleRecordStream('stop')
      }
    }

    const videoElement = ref<HTMLVideoElementWithPip|null>(null)
    onMounted(async() => {
      await handleLiveCameraStream('start')
      videoElement.value = document.querySelector('video') as HTMLVideoElementWithPip
      // Picture-in-Pictureのイベントを登録
      videoElement.value.addEventListener('enterpictureinpicture', () => togglePipState(true))
      videoElement.value.addEventListener('leavepictureinpicture', () => togglePipState(false))
    })

    const startPip = () => {
      const videoElementValue = videoElement.value
      if (videoElementValue) {
        enablePip(videoElementValue)
      } else {
        console.error('video要素が取得できませんでした')
      }
    }

    // TODO: 処理をvideoElement、startPipと共にuse関数に移行する
    const onClickPip = () => isEnabledPip.value ? endPip() : startPip()

    const cameraStreamClass = computed(() => ({ 'hide-dispalay': isEnabledPip.value }))

    return {
      cameraStream,
      onHandleLiveDisplayStream,
      isLiveDisplayStream,
      startPip,
      endPip,
      onClickPip,
      isEnabledPip,
      cameraStreamClass,
    }
  },
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  > section.canvas {
    > canvas {
      background-color: #222;
    }
  }
}

.video-section {
  position: relative;
}

.video-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.btn-area {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.video-box {
  background: url('../../assets/diddy-logo.png') no-repeat 5% 5% / 20%,
              linear-gradient(90deg, #F39B23 0%, #FFFFFF 100%);
  position: relative;
  p {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    position: absolute;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.hide-dispalay {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: -1000;
}
</style>
