import { ref, computed } from 'vue'
import { ApplicationError } from '@/features/home/composables/useException'
import { EventName } from '@/features/home/types'

class DisplayStreamError extends ApplicationError {
  errorMessage = () => {
    if (this.name === 'NotAllowedError') {
      return '共有する画面が選択されていません。共有する画面を選択してください。'
    } else {
      return '画面を共有した時に問題が発生しました。'
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDisplayStream = () => {
  const displayStream = ref<MediaStream | null>(null)
  const constraints = { video: true, audio: false }

  // 画面共有の開始
  const startDisplayStream = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // キャンセル時consoleに以下のようなwarningが出るが問題ないとのこと (https://stackoverflow.com/questions/52298691/getting-typeerror-caller-callee-and-arguments-properties-may-not-be-acc)
    // Getting TypeError: 'caller', 'callee', and 'arguments' properties may not be accessed on strict mode
    await navigator.mediaDevices.getDisplayMedia(constraints)
      .then((stream: MediaStream) => {
        updateDisplayStream(stream)
        console.log('start displayStream', displayStream.value)
      })
      .catch((error: MediaStreamError) => {
        throw new DisplayStreamError(error)
      })
  }

  // 画面共有の停止
  const stopDisplayStream = () => {
    const tracks = displayStream.value?.getTracks()
    tracks?.forEach((track) => track.stop())
    updateDisplayStream(null)
    console.log('stop displayStream', displayStream.value)
  }

  // 画面共有の更新
  const updateDisplayStream = (stream: MediaStream | null) => {
    displayStream.value = stream
  }

  const isLiveDisplayStream = computed(() => !!displayStream.value)

  const handleLiveDisplayStream = async (eventName: EventName) => {
    try {
      if (eventName === 'start') {
        await startDisplayStream()
      } else if (eventName === 'stop') {
        stopDisplayStream()
      }
    } catch (error) {
      alert(error.errorMessage())
      return
    }
  }

  return {
    displayStream,
    isLiveDisplayStream,
    handleLiveDisplayStream
  }
}
