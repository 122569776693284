import { ref, Ref } from 'vue'

// === TODO: versionが上がって不要になったら公式の型を使うよう置き換える
interface DocumentWithPip extends Document {
  readonly pictureInPictureEnabled: boolean;
  readonly pictureInPictureElement: Element | null;
  exitPictureInPicture(): Promise<void>;
}

export interface HTMLVideoElementWithPip extends HTMLVideoElement {
  requestPictureInPicture(): Promise<PictureInPictureWindow>;
}

interface PictureInPictureWindow extends EventTarget {
  readonly height: number;
  onresize: ((this: PictureInPictureWindow, ev: Event) => unknown) | null;
  readonly width: number;
  addEventListener<K extends keyof PictureInPictureWindowEventMap>(type: K, listener: (this: PictureInPictureWindow, ev: PictureInPictureWindowEventMap[K]) => unknown, options?: boolean | AddEventListenerOptions): void;
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
  removeEventListener<K extends keyof PictureInPictureWindowEventMap>(type: K, listener: (this: PictureInPictureWindow, ev: PictureInPictureWindowEventMap[K]) => unknown, options?: boolean | EventListenerOptions): void;
  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}

interface PictureInPictureWindowEventMap {
  'resize': Event;
}
// ==== ここまで

type PictureInPicture = {
  isEnabledPip: Ref<boolean>
  enablePip: (videoElement: HTMLVideoElementWithPip) => void
  disablePip: () => void
  togglePipState: (enabled: boolean) => void
}

export const usePictureInPicture = (): PictureInPicture => {
  const isEnabledPip = ref(false)

  const enablePip = (videoElement: HTMLVideoElementWithPip): void => {
    const tmpDocument = document as DocumentWithPip
    if (tmpDocument.pictureInPictureEnabled) {
      try {
        videoElement.requestPictureInPicture()
      } catch (error) {
        console.error(error)
      }
      isEnabledPip.value = true
    }
  }

  const disablePip = (): void => {
    const tmpDocument = document as DocumentWithPip
    if (tmpDocument.pictureInPictureElement || isEnabledPip.value) {
      tmpDocument.exitPictureInPicture()
      isEnabledPip.value = false
    }
  }

  const togglePipState = (enabled: boolean) => {
    isEnabledPip.value = enabled
  }

  return {
    isEnabledPip,
    enablePip,
    disablePip,
    togglePipState,
  }
}
