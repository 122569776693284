import { ref } from 'vue'
import { EventName } from '../types'

type HandleStream = (eventName: EventName) => void

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTimeManagement = (handleLiveDisplayStream: HandleStream, handleRecordStream: HandleStream) => {
  const firstTimeAnnounceSec = 150_000
  const secondTimeAnnounceSec = 240_000
  const lastAnnounceSec = 300_000

  const timeoutIds = ref<number[]>([])

  // TODO: 他でも音声を使いたくなったら、別ファイルに切り出す
  const speech = (text: string) => {
    const speech = new SpeechSynthesisUtterance()
    speech.text = text
    speech.lang = 'ja-JP'
    speech.volume = 0.1
    window.speechSynthesis.speak(speech)
  }

  const setTimer = (text: string, time: number) => setTimeout((text: string) => { speech(text) }, time, text)

  const endTimer = async () => {
    return setTimeout(async() => { 
      speech('録画を終了しました')
      await handleLiveDisplayStream('stop')
      await handleRecordStream('stop')
    }, lastAnnounceSec)
  }

  const startTime = async () => {
    // 途中でタイマーを破棄できるように、setTimeoutのIDを保持しておく
    timeoutIds.value = [
      setTimer('2分30秒経過しました', firstTimeAnnounceSec),
      setTimer('4分経過しました', secondTimeAnnounceSec),
      await endTimer(),
    ]
  }

  const endTime = () => {
    timeoutIds.value.forEach((id) => { clearTimeout(id) })
  }

  return {
    startTime,
    endTime,
  }
}
